<template>
  <a-select v-model="selectedOption" ref="mySelect" @paste="handlePaste">
    <a-select-option v-for="item in options" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
  </a-select>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
      ],
      selectedOption: null
    };
  },
  methods: {
    handlePaste(event) {
      // 阻止默认粘贴事件
      event.preventDefault();

      // 获取粘贴的内容
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');

      // 在这里你可以自定义处理粘贴的内容，例如过滤、验证等操作
      // 然后将处理后的内容设置为选择框的值
      this.selectedOption = pastedText;
    }
  }
};
</script>
